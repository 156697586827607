// LengthMixin.js
export const LengthMixin = {
    methods: {
      calculateLengthAndDisplayMessage(str) {
        const length = str.length;
        if (length > 10) {
          console.log(`The string is long (${length} characters)`);
        } else {
          console.log(`The string is short (${length} characters)`);
        }
      }
    }
  };

  