<template>
    <div class="wrapper">
        <div class="row">
            <div class="col-lg-4 col-12">
                <div class="p-3">
                    <h1>AI/ML Projects</h1>
                </div>
            </div>

            <div class="col-lg-8 col-12">

                <div class="project" v-for="(project, index) in projects" :key="index">
                    <p>{{ project.period }}</p>
                    <h4>{{ project.name }}</h4>
                    <h6>{{ project.associated }}</h6>
                    <p>{{ project.description }}</p>
                    <ul class="horizontal-skills">
                        <li v-for="(skill, skillIndex) in project.skills" :key="skillIndex">{{ skill }}</li>
                    </ul>
                    <a :href="project.githubLink" target="_blank"><i class="fab fa-github"></i> Github Link</a>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import HF_DiseaseClassifierEmbed from '@/components/HF_DiseaseClassifierEmbed.vue';
import spaces from '@/assets/icons/spaces.svg'
export default {
    components: {
        HF_DiseaseClassifierEmbed, spaces
    },
    data() {
        return {
            projects: [
                {
                    name: 'Gym-Motion-Pose-AI: Body Posture Intermediator',
                    period: 'Jan 2024 - Present',
                    associated: 'Lambton College',
                    description: "A project that aims to act as intermediator for exercises by applying ML and Computer Vision on human pose estimation. Targeting to provide active feedback of the pose and exercise motion using an ensemble of various models. .",
                    skills: ['OpenCV','Human Pose Estimation', 'Natural Language Processing (NLP)', 'Python (Programming Language)', 'Machine Learning'],
                    githubLink: 'https://github.com/Shanover77/mini_ats'
                },
                {
                    name: 'Mini-ATS: Resume Matching',
                    period: 'Jan 2024 - Present',
                    associated: 'Lambton College',
                    description: "The primary goal of this Python program is to provide an Applicant Tracking System (ATS) percent score by comparing the content of resumes (in the 'resumes' folder) to job description text files (in the 'jds' folder). The program utilizes keyword extraction to calculate the similarity percentage between the keywords in resumes and job descriptions.",
                    skills: ['Natural Language Processing (NLP)', 'Python (Programming Language)', 'Machine Learning'],
                    githubLink: 'https://github.com/Shanover77/mini_ats'
                },
                {
                    name: 'MedBot - Text2Text Generation - Based on GODEL',
                    period: 'Aug 2023 - Aug 2023',
                    associated: 'Lambton College',
                    description: 'Trained model for text2text generation using Microsoft Godel (Large). Used a particular dataset to train. Experimental project.',
                    skills: ['Fine Tuning', 'Deep Learning', 'Artificial Intelligence (AI)', 'Natural Language Processing (NLP)', 'PyTorch', 'Hugging Face'],
                    githubLink: 'https://huggingface.co/shanover/medbot_godel_v3'
                },
                {
                    name: 'Disease Prognosis by fine-tuning BERT',
                    period: 'Jul 2023 - Jul 2023',
                    associated: 'Lambton College',
                    description: 'Disease Prognosis is a Sequence Classifier based on BERT. Trained to classify 41 diseases based on symptoms. This is an extension to the earlier project MedBot.',
                    skills: ['Fine Tuning', 'Deep Learning', 'Artificial Intelligence (AI)', 'BERT (Language Model)', 'Prompt Engineering', 'PyTorch', 'Hugging Face', 'Machine Learning'],
                    githubLink: 'https://huggingface.co/spaces/shanover/spc_symps_disease_bert_v3_c41'
                },
                {
                    name: 'Summify - Whisper based YouTube Video Summarization/Transcription',
                    period: 'Jun 2023 - Jul 2023',
                    associated: 'Lambton College',
                    description: "An AI based tool, using OpenAI whisper model for transcribing a YouTube video. Aim is to summarize YouTube video into various representations, as per user's requirements. Current stage: MVP Next stage: Feature upscaling, public access with production site. Further plans: Make it scalable",
                    skills: ['OpenAI', 'FlaskAPI', 'Artificial Intelligence (AI)', 'Prompt Engineering', 'Machine Learning'],
                    githubLink: 'https://github.com/Shanover77/summify-fe'
                },
                // Add more projects as needed
            ],
        }
    }
}
</script>
<style scoped>
.wrapper {
    padding: 5%;
}

.project {
    margin-bottom: 5%;
}</style>