<template>
    <div class="wrapper">
        <div class="row">
            <div class="col-lg-4 col-12">
                <div class="p-3">
                    <h1>Certifications</h1>
                    <p>Not so much as of now, but it was definitely a morale boost at that time :)</p>
                </div>
            </div>
            <div class="col-lg-8 col-12">
                <div class="blur-body">
                    <div class="certificate">
                        <div class="svg">                                                         
                            <inline-svg :src="require('../assets/icons/certificate_new.svg')" class="icon" />
                        </div>
                        <div class="content">
                            <p>2018</p>
                            <h1>BSNL - 7 Day Workshop of PHP Development</h1>
                            <p>Created an e-commerce website</p>
                            <h2>Bharat Sanchar Nigam Limited (BSNL), *******, India</h2>
                        </div>
                    </div>
                </div>

                <div class="blur-body">
                    <div class="certificate">
                        <div class="svg">                                                         
                            <inline-svg :src="require('../assets/icons/certificate_new.svg')" class="icon" />
                        </div>
                        <div class="content">
                            <p>2018</p>
                            <h1>BSNL - 7 Day Workshop of Network Security</h1>
                            <p>Worked on setting up Cisco Routers, Setting up Networks, Subnet Masking, etc.</p>
                            <h2>Bharat Sanchar Nigam Limited (BSNL), *******, India</h2>
                        </div>
                    </div>

                </div>            
            </div> 
        </div>
    </div>
</template>

<script>

import InlineSvg from 'vue-inline-svg';
import Popper from "vue3-popper";

export default {
    name:'Certifications',
     components:{
        InlineSvg,
        Popper
    }
}
</script>

<style scoped>
    .wrapper{
        padding:5%;
        font-family: 'Inter',sans-serif;
    }
    .blur-body{
        margin-bottom:20px;
    }

    .text-dim{
        color:#565656;
    }

    .content p{
        color:#454545;
        margin-bottom:7px;
    }
    .content h1{
        font-size: 1.2rem;
        margin-bottom:4px;
        color:#344CB7;
    }
    .content h2{
        font-size: 1rem;
        margin:0px;
        margin-bottom:4px;
        color:#434343;
    }

    .content ul > li{
        color:#454545;
        font-size:0.9rem;
    }

    .icon{
        cursor: pointer;
        width:60px;
        height:60px;
        margin:10px;
        opacity: 0.6;
        filter: grayscale(1);
        transition: 1ms ease-in;
    }

    .certificate{
        display:flex;
        flex-direction:row;
        gap:2rem;
    }

     @media only screen and (max-width: 750px) {
        .icon{
             display: none;
        }
        .certificate{
            display:block;
        }
    }
    
</style>