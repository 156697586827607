<template>
    <div class="wrapper">
        <div class="row">
            <div class="col-lg-4 col-12">
                <div class="p-3">
                    <h1>Soft Skills</h1>         
                    <h6 class="p-1 text-dim">Having the right attitude of being always in the Student Mode</h6>           
                   
                </div>
            </div>
            <div class="col-lg-8 col-12">
                <div class="blur-body">
                     <p class="groove"><strong>Personality: </strong><a href="https://bigfive-test.com/result/61c89c8ff8f9f00009aad964" target="_blank">Here</a> is my Big Five Personality test result from <em>bigfive-test.com</em></p>
                    <ul class="mt-4">
                        <li>
                            Very good at English Speaking, writing, listening
                        </li>
                        <li>Presentation Skills
                            <ul>
                                <li>Business Writing</li>
                                <li>Analytical/Educational Report Writing</li>
                                <li>Creative and inventive in approach</li>
                                <li>Brain Storming</li>
                                <li>PowerPoints</li>
                                <li>Posters</li>
                                <li>Canva</li>
                                <li>Adobe XD</li>
                                <li>Google Sheets</li>
                                <li>Figma</li>
                                <li>Confluence</li>
                            </ul>
                        </li>
                        <li>Problem Solving Attitude</li>
                        <li>Team Player</li>
                        <li>Trains response, hand-eye coordinate, reasoning by playing high-paced BR video game</li>
                        <li>Can work lead and take responsiblities of SDLC</li>
                        <li>Been a lead in project in a team of 3</li>
                        <li>Sportmanship, Former soccer player for BDFA (Bharuch District Footbal Association)</li>
                        <li>Stays fit* (Kinda getting difficult with so much at hand)</li>
                    </ul>
                </div>
            </div> 
        </div>
    </div>
</template>

<script>

import InlineSvg from 'vue-inline-svg';
import Popper from "vue3-popper";

export default {
    name:'SoftSkills',
     components:{
        InlineSvg,
        Popper
    }
}
</script>

<style scoped>
    .wrapper{
        padding:5%;
        font-family: 'Inter',sans-serif;
    }

    ul > ul > li{
        list-style-type:none;
    }

    .groove{
        display: inline-block;
        border-radius: 6px;
        background: #ffffff;
        box-shadow: inset 2px 2px 4px #d9d9d9,
                    inset -2px -2px 4px #ffffff;
        padding:12px 17px;
    }

    .soft-skills{
        background: rgba( 255, 255, 255, 0.32 );
        box-shadow: 0 4px 12px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 4px );
        -webkit-backdrop-filter: blur( 4px );
        border-radius: 10px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        padding:16px;
    }

    .blur-body > p{
        color:#454545;
        margin-bottom:7px;
    }
    .blur-body > h1{
        font-size: 1.3rem;
        margin-bottom:4px;
    }
    .blur-body > h2{
        font-size: 1rem;
        margin:0px;
        color:#232323;
    }

</style>