<template>
  <router-view />
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500&family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');
@import url('bootstrap/bootstrap.min.css');


body {
  font-family: "EB Garamond", serif!important;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: #F5F5F5;  
  /* background-image: radial-gradient(#c5f7ff 2px, transparent 2px), radial-gradient(#c5f7ff 2px, #ffffff 2px); */
  background-size: 80px 80px;
  background-position: 0 0, 40px 40px;
}

h1{
  line-height: 5rem!important;
}

</style>
