<template>
    <div class="wrapper">
        <div class="row">
            <div class="col-lg-4 col-12">
                <div class="p-3">
                    <h2>Professional Experience</h2>
                    <h5 class="p-1">4+ years</h5>
                </div>
            </div>

            <div class="col-lg-8 col-12">

                <!-- <div class="blur-body mb-2">
                    <h2>💼🤝 Co-founder</h2>
                    <p class="mt-2">Pathfynder, Toronto, Canada</p>
                    <p>Apr 2023 - Present | Project Management | AI/ML Price Modeling</p>
                    <p class="mt-2">Pathfynder - a startup (cliché) aiming to solve the latent issues of transportation faced by the working force of Ontario. Funded by RBC Bank's EPIC Founders Program. We are about to go Closed Beta soon (tentative July-Aug 2023).
                    Looking for mentors who can guide on this one.</p>
                </div> -->
                <div class="blur-body mb-2">
                    <h2>👨‍💻 Freelance Developer</h2>
                    <p class="mt-2">iDelivery - Medication delivery in toronto, Toronto, Canada</p>
                    <p>Jan 2023 - July 2023<br/>Casual Freelance<br/>Custom made operational website</p>
                    <p class="mt-2">From scratch, created novel solutions for their daily mission-critical deliveries and reduced cost of online operations site by close to 50%</p>
                    <ul>
                        <li>Delivery management</li>
                        <li>Delivery optimization</li>
                        <li>Route optimization</li>
                        <li>Auto-assigning orders</li>
                        <li>Managing users</li>
                        <li>Reporting/Exporting</li>
                    </ul>

                </div>
            
                <div class="blur-body">
                    <h2>💼 Full Stack Developer</h2>
                    <p class="mt-2">Metahire Pvt. Ltd. <em>(formerly GSE Hiring Pvt. Ltd.)</em></p>
                    <p>July 2019 - November 2022<br/>Full-time<br/>Pune, India</p>
                    <p class="mt-2">Worked as a team member, individual and lead on projects and finally leading our mainstream full stack web application for recruitment, making meaningful choices.</p>
                    <ul>
                        <li>Worked on a Full Stack Web App which became our mainstream product and sole project running during the 2019 pandemic as well.</li>
                        <li>Gained hands-on experience in working for a startup.</li>
                        <li>Managed complete life-cycle from server maintenance to project deployment, debugging, development, iterations and upscaling.</li>
                        <li>Contributed to critical decision making and tech reviews to the higher ups</li>
                        <li>Managed servers, domains, DNS, APIs, integrations, and other team mates as well</li>
                    </ul>

                </div>
            </div> 
        </div>
    </div>
</template>

<script>

import InlineSvg from 'vue-inline-svg';
import Popper from "vue3-popper";

export default {
    name:'ExperienceBlock',
     components:{
        InlineSvg,
        Popper
    },
    data(){
        return{
            experience:'',
            startDate:'07/01/2019'
        }
    },
    mounted(){
        this.calcDiff();
    },
    methods:{
        calcDiff(){
            const date1 = new Date(this.startDate);
            var today  = new Date();
            var date2 = today.toLocaleDateString("hi-IN");

            console.log(today.toLocaleDateString("hi-IN"));
            date2 = today;

            const diffTime = Math.abs(date2 - date1);            
            const yearsDiff = (diffTime / (1000 * 60 * 60 * 24 * 365)).toFixed(2); 
            this.experience = yearsDiff;
        }
    }
}
</script>

<style scoped>
    .wrapper{
        padding:5%;
        font-family: 'Inter',sans-serif;
    }

    .experience-body{
        background: rgba( 255, 255, 255, 0.66 );
        box-shadow: 0 4px 12px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 12px );
        -webkit-backdrop-filter: blur( 12px );
        border-radius: 10px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        padding:20px;
    }

    .blur-body > p{
        color:#454545;
        margin-bottom:7px;
    }
    .blur-body > h1{
        font-size: 1.3rem;
        margin-bottom:4px;
    }
    .blur-body > h2{
        font-size: 1rem;
        margin:0px;
        color:#232323;
    }

    .blur-body > ul > li{
        color:#454545;
        font-size:1rem;
    }

    .tech-stack:hover{
        transform: scale(1.1);
    }

    .computed{
        font-size:10px;
        color:#565656;
    }
</style>