import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyChddO1-DgyfMdpmlr-BcHoZj4mouV07L8",
  authDomain: "shanover-ml-01.firebaseapp.com",
  projectId: "shanover-ml-01",
  storageBucket: "shanover-ml-01.appspot.com",
  messagingSenderId: "476364055401",
  appId: "1:476364055401:web:c58734e7cc4fb2524013f8"
};

const app = firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
