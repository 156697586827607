<template>
  <div class="home">
    <IntroHeader />
    <!-- <ExperienceBlock/> -->
    <!-- <Education/> -->
    <!-- <TechStack /> -->
    <Projects />
    <!-- <SoftSkills/> -->
    <!-- <Certifications/> -->
    <!-- <Footer/> -->

  </div>
</template>

<script>
import Projects from '@/components/Projects.vue'
import IntroHeader from '@/components/IntroHeader'
import ExperienceBlock from '@/components/ExperienceBlock.vue'
import TechStack from '@/components/TechStack.vue'
import SoftSkills from '@/components/SoftSkills.vue'
import Education from '@/components/Education.vue'
import Certifications from '@/components/Certifications.vue'
import Footer from '@/components/Footer.vue'
import { LengthMixin } from '@/mixins/LengthMixin';

export default {
  name: 'Home',
  mixins: [LengthMixin],
  components: {
    Certifications,
    Education,
    ExperienceBlock,
    Footer,
    IntroHeader,
    Projects,
    SoftSkills,
    TechStack
  },
  methods:{
    check(){
      this.calculateLengthAndDisplayMessage('This is nince');
    }
  }
}
</script>

<style>
  /* ... Your existing styles ... */

  :root {
    --neon-text-color: #A3DA8D;
    --neon-text-color2: #CFFFDC;
    --primary-background: rgba(255, 255, 255, 0.66);
    --text-dim-color: #565656;
  }

  .blur-body {
    background: var(--primary-background);
    /* ... rest of the styles ... */
  }

  .text-dim {
    color: var(--text-dim-color);
  }

  /* ... rest of the styles ... */
</style>
