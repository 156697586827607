<template>
    <div>
        <iframe
            src="https://shanover-spc-symps-disease-bert-v3-c41.hf.space"
            frameborder="0"
            width="100%"
            height="350"
        ></iframe>

    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>