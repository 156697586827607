<template>
    <div class="wrapper">
        <div class="row">
            <div class="col-lg-4 col-12">
                <div class="p-3">
                    <h1>Technical Skills</h1>
                    <h6 class="p-1 text-dim">Explore the technical skills in my arsenal</h6>
                </div>
            </div>
            <div class="col-lg-8 col-12">
                <div class="blur-body">
                    <div class="sub">
                        <p>Like the back of my hand</p>
                        <ul class="nav">
                            <li @click="enableAll">All</li>
                            <li @click="enableAI"><span class="ai-end"></span> AI/ML/Data Science</li>
                            <li @click="enableFend"><span class="f-end"></span> FrontEnd</li>
                            <li @click="enableBend"><span class="b-end"></span> BackEnd</li>
                            <li @click="enableDevops"><span class="d-ops"></span> DevOps</li>
                            <li @click="enableServer"><span class="ser"></span> Server</li>
                        </ul>
                    </div>
                   <div class="skill-collection">

                       <!-- AI/ML -->
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Python</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Prompt Engineering</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Pandas</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Numpy</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> 🤗 Transformers</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> 🤗 Huggingface</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> 🤗 BERT Fine-tuning</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Scikit/Learn</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Natural Language Processing</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Seaborn</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Handling multi-modal data</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Visualization/Presentation</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Business/Analytical Reports</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Tensorflow</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> PyTorch</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Matplotlib</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Supervised Learning</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Unsupervised Learning</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Ensemble Modeling</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Data Scraping Techniques</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Data Mining</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Data Wrangling</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Data Analysis</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Feature Engineering</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Model Optimization</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Best Practices for AI Problems</span>
                       <span v-if="techStackAll || techStackAI"><span class="ai-end"></span> Responsible AI</span>


                       <span v-if="techStackAll">Web Development</span>
                       <span v-if="techStackAll || techStackFend"><span class="f-end"></span> VueJs (Vue 3)</span>
                       <span v-if="techStackAll || techStackFend"><span class="f-end"></span> ReactJS (Beginner)</span>
                       <span v-if="techStackAll || techStackFend"><span class="f-end"></span> Tailwind</span>
                       <span v-if="techStackAll || techStackBend"><span class="b-end"></span> PHP</span>
                       <span v-if="techStackAll || techStackFend"><span class="f-end"></span> JavaScript</span>
                       <span v-if="techStackAll || techStackFend"><span class="f-end"></span> Responsive Designs</span>
                       <span v-if="techStackAll || techStackBend"><span class="b-end"></span> SQL</span>                      
                       <span v-if="techStackAll || techStackBend"><span class="b-end"></span> Backend</span>
                       <span v-if="techStackAll || techStackBend"><span class="b-end"></span> MySQL</span>
                       <span v-if="techStackAll || techStackBend"><span class="b-end"></span> FastAPI</span>
                       <span v-if="techStackAll || techStackBend"><span class="b-end"></span> FlaskAPI</span>
                       <span v-if="techStackAll || techStackBend"><span class="b-end"></span> Python</span>
                       <span v-if="techStackAll || techStackBend"><span class="b-end"></span> NoSQL</span>
                       <span v-if="techStackAll || techStackServer || techStackBend"><span class="b-end"></span><span class="ser"></span> Apache</span>
                       <span v-if="techStackAll || techStackDevops"><span class="d-ops"></span> Agile Work</span>
                       <span v-if="techStackAll || techStackFend"><span class="f-end"></span> jQuery</span>
                       <span v-if="techStackAll || techStackFend"><span class="f-end"></span> Bootstrap</span>
                       <span v-if="techStackAll || techStackFend"><span class="f-end"></span> Sass</span>
                       <span v-if="techStackAll || techStackDevops"><span class="d-ops"></span> Jira</span>
                       <span v-if="techStackAll || techStackBend"><span class="b-end"></span> Firebase</span>
                       <span v-if="techStackAll || techStackDevops"><span class="d-ops"></span> GitHub</span>
                       <span v-if="techStackAll || techStackDevops"><span class="d-ops"></span> Version Control</span>
                       <span v-if="techStackAll || techStackDevops"><span class="d-ops"></span> NPM, Webpack, Node</span>
                       <span v-if="techStackAll || techStackDevops"><span class="d-ops"></span> Slack</span>
                       <span v-if="techStackAll || techStackBend"><span class="b-end"></span> Netlify</span>
                       <span v-if="techStackAll || techStackDevops"><span class="d-ops"></span> Google Analytics</span>
                       <span v-if="techStackAll || techStackDevops"><span class="d-ops"></span> PageSpeed</span>
                       <span v-if="techStackAll || techStackServer || techStackBend"><span class="b-end"></span><span class="ser"></span> Linux</span>
                       <span v-if="techStackAll || techStackDevops"><span class="d-ops"></span> SEO</span>
                       <span v-if="techStackAll || techStackDevops"><span class="d-ops"></span> Apache Server</span>
                       <span v-if="techStackAll || techStackDevops"><span class="d-ops"></span> DNS/Domain Management</span>
                       <span v-if="techStackAll || techStackBend"><span class="d-ops"></span> API Integration</span>
                       <span v-if="techStackAll || techStackDevops"><span class="d-ops"></span> GitHub Actions (CI/CD)</span>
                       <span v-if="techStackAll || techStackBend"><span class="b-end"></span> Email Deliverability Problems</span>                       
                   </div>

                    <div class="sub" v-if="false">
                        <p>Intermediate</p>
                        <div class="skill-collection">
                            <span class="udemy">TypeScript <UdemyStamp/></span>
                            <span>Adobe XD</span>
                            <span>Selenium Lamba</span>
                        </div>
                    </div>

                    <!-- <div class="sub">
                        <p>As of now, bending the learning curves of...</p>
                    </div>
                    <div class="skill-collection">
                        <span>Machine Learning (FT Student)</span>
                        <span>ML Models in Production</span>
                        <span>PgVector</span>
                        <span>Pinecone</span>
                        <span>LangChain</span>
                        <span>LLM/Embeddings/Transformers</span>
                        <span>And exploring so many others...(phew exponential growth)</span>
                         <span>Jest</span> -->
                       <!-- <span class="udemy">Docker <UdemyStamp/></span>
                       <span class="udemy">Kubernetes <UdemyStamp/></span>
                       <span class="udemy">TypeScript <UdemyStamp/></span>                      
                    </div> -->
                    <!-- <a href="https://stackshare.io/shanover77/web-dev-stack-work" target="_blank" ><img src="https://img.shields.io/badge/tech-stack-0690fa.svg?style=flat" alt="Shanover Work Tech Stack" class="tech-stack"></a> -->
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import InlineSvg from 'vue-inline-svg';
import Popper from "vue3-popper";
import UdemyStamp from "@/components/UdemyStamp.vue"

export default {
    name:'TechStack',
     components:{
        InlineSvg,
        Popper,
        UdemyStamp
    },
    data(){
        return{
            techStackAll:true,
            techStackFend:false,
            techStackBend:false,
            techStackServer:false,
            techStackDevops:false,
            techStackAI:false,
        }
    },
    methods:{
        enableAI(){
            this.techStackAI=true;
            this.techStackFend=false;
            this.techStackAll=false;
            this.techStackBend=false;
            this.techStackServer=false;
            this.techStackDevops=false;   
        },
        enableFend(){
            this.techStackFend=true;
            this.techStackAll=false;
            this.techStackBend=false;
            this.techStackServer=false;
            this.techStackDevops=false;   
            this.techStackAI=false;         
        },
        enableBend(){
            this.techStackFend=false;
            this.techStackAll=false;
            this.techStackBend=true;
            this.techStackServer=false;
            this.techStackDevops=false;      
            this.techStackAI=false;
        },
        enableServer(){
            this.techStackFend=false;
            this.techStackAll=false;
            this.techStackBend=false;
            this.techStackServer=true;
            this.techStackDevops=false;  
            this.techStackAI=false;
        },
        enableDevops(){
            this.techStackFend=false;
            this.techStackAll=false;
            this.techStackBend=false;
            this.techStackServer=false;
            this.techStackDevops=true;  
            this.techStackAI=false;
        },
        enableAll(){
            this.techStackAll=true;
            this.techStackFend=false;            
            this.techStackBend=false;
            this.techStackServer=false;
            this.techStackDevops=false;  
            this.techStackAI=false;
        }
        
    }
}
</script>

<style scoped>
    .wrapper{
        padding:5%;
        font-family: 'Inter',sans-serif;
    }

    .nav{
        margin-bottom:20px;
    }

  
    .nav > li{
        position:relative;
        margin-bottom:4px;
        cursor:pointer;
        margin-right:10px;
        font-size:0.9rem;
        color:#344CB7;
        padding:3px 8px;
        border-radius: 2px;
        background: linear-gradient(145deg, #ffffff, #e6e6e6);
        /* box-shadow:  1px 1px 2px #d9d9d9,
                    -1px -1px 2px #ffffff; */
    }

     .nav > li:hover{
        border-radius: 2px;
        background: #ffffff;
        box-shadow: inset 1px 1px 2px #d9d9d9,
                    inset -1px -1px 2px #ffffff;
    }


    .f-end{
        position:absolute;
        left:-1px;
        top:-1px;border-top-left-radius:60px;
        opacity: 0.7;
        height:10px;
        width:10px;
        margin-right:3px;
        display:inline-block;
        background:#0af;
        border-bottom-right-radius:100px;        
        /* box-shadow: inset 2px 2px 0px #a6d9e6,
                    inset -2px -2px 0px #caffff; */
    }

    .ai-end{
        position:absolute;
        left:-1px;
        top:-1px;border-top-left-radius:60px;
        opacity: 0.7;
        height:12px;
        width:12px;
        margin-right:3px;
        display:inline-block;
        background:#FF2171;
        border-bottom-right-radius:100px;        
        /* box-shadow: inset 2px 2px 0px #a6d9e6,
                    inset -2px -2px 0px #caffff; */
    }

    .b-end{
        position:absolute;
        left:-1px;
        top:-1px;border-top-left-radius:60px;
        opacity: 0.7;
        height:10px;
        width:10px;
        margin-right:3px;
        display:inline-block;
        border-bottom-right-radius:100px;        
        background: #e2b8ff;
        /* box-shadow: inset 2px 2px 0px #cba6e6,
                    inset -2px -2px 0px #f9caff; */
    }

    .d-ops{
        position:absolute;
        left:-1px;
        top:-1px;border-top-left-radius:60px;
        opacity: 0.7;
        height:10px;
        width:10px;
        margin-right:3px;
        display:inline-block;
        border-bottom-right-radius:100px;  
        background: #f7ff8a;
        /* box-shadow: inset 2px 2px 0px #dee67c,
                    inset -2px -2px 0px #ffff98; */
    }

    .ser{
        position:absolute;
        left:-1px;
        top:-1px;border-top-left-radius:60px;
        opacity: 0.7;
        height:10px;
        width:10px;
        margin-right:3px;
        border-bottom-right-radius:100px;   
        display:inline-block;
        background: #8aff9d;
        /* box-shadow: inset 2px 2px 0px #7ce68d,
                    inset -2px -2px 0px #98ffad; */
    }

    .li-active{
        border-radius: 2px;
        background: linear-gradient(145deg, #ffffff, #e6e6e6);
        box-shadow:  2px 2px 4px #e6e6e6,
                    -2px -2px 4px #ffffff;
    }
   

    .skills-body{
        background: rgba( 255, 255, 255, 0.32 );
        box-shadow: 0 4px 12px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 4px );
        -webkit-backdrop-filter: blur( 4px );
        border-radius: 10px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        padding:16px;
    }

    .blur-body > .sub > p{
        display:inline-block;
        color:#344CB7;
        border-bottom:2px #f1f1f1 solid;
    }

    .skill-collection{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom:32px;
    }

    .skill-collection > span {
        position: relative;
        padding:4px 12px;
        /* background: rgba( 255, 255, 255, 0.15 );
        box-shadow: 0 1px 1px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 10px );
        -webkit-backdrop-filter: blur( 10px );
        border-radius: 4px;
        font-weight: bold;
        border: 1px solid rgba( 255, 255, 255, 0.18 ); */
        border-radius: 6px;
        font-weight: bold;
        background: #ffffff;
        box-shadow: inset 1px 1px 3px #d9d9d9,
                    inset -1px -1px 3px #ffffff;
        font-family: 'Rajdhani', sans-serif;
        margin:3px 10px 10px 2px;
    }

    .blur-body > p{
        color:#454545;
        margin-bottom:7px;
    }
    .blur-body > h1{
        font-size: 1.3rem;
        margin-bottom:4px;
    }
    .blur-body > h2{
        font-size: 1rem;
        margin:0px;
        color:#232323;
    }

    .blur-body > ul > li{
        color:#454545;
        font-size:0.9rem;
    }

    .tech-stack:hover{
        transform: scale(1.1);
    }

    .icon{
        height:20px;
        width:20px;
        margin-left:8px;
    }
</style>