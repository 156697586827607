<template>
    <div class="wrapper text-center">

        <div class="circle-wrap">
            <!-- <div class="circle">
                <video src="@/assets/avatar/avatar.mp4" autoplay loop muted></video>
            </div> -->
            <div class="circle">
                <img src="@/assets/avatar/avatar.gif" alt="Animated Avatar" />
            </div>

        </div>
        <div class="sub-line">
            <h2>"applied knowledge is power, compassion is common sense"</h2>
            <p><small>~ shanoverali</small></p>
        </div>
        <div class="row mt-5">
            <div class="col-md-6 col-12">
                <div class="name-wrapper">
                    <h4 class="name">Shanoverali <br /><span>Saiyed</span></h4>
                    <p>Machine Learning, Frontend Developer, Backend Engineer</p>
                    <p class="import"><code style="color: #AAD7D9">import { <span style="color:#0B60B0">machine-learning, full-stack</span> } from <span style="color:#0B60B0">shanover.dev</span></code></p>

                    <!-- <p class="mb-0" style="color:white"><i class="fas fa-check-circle"></i> Roles interested:</p> -->
                </div>
            </div>

            <div class="col-md-6 col-12">
                <h5 class="text-center mb-3">Profile Links:</h5>
                <div class="socials-wrapper">
                    <div class="collections">
                        <div class="social-icon-wraper">
                            <a href="https://www.linkedin.com/in/shanover-saiyed/" target="_blank">
                                <Popper content="Connect on LinkedIn" :hover="true" offsetDistance="0">
                                    <inline-svg :src="require('../assets/icons/linkedin.svg')" class="icon"
                                        tooltip="Linkedin" alt="LinkedIn Link" />
                                </Popper>
                            </a>
                        </div>

                        <div class="social-icon-wraper">
                            <a href="https://huggingface.co/shanover" target="_blank">
                                <Popper content="Hugging Face" :hover="true" offsetDistance="0">
                                    <img src="https://huggingface.co/front/assets/huggingface_logo-noborder.svg" class="icon"/>
                                </Popper>
                            </a>
                        </div>

                        <div class="social-icon-wraper">
                            <a href="https://github.com/Shanover77" target="_blank">
                                <Popper content="GitHub Profile" :hover="true" offsetDistance="0">
                                    <inline-svg :src="require('../assets/icons/github.svg')" class="icon"
                                        alt="GitHub Profile" />
                                </Popper>
                            </a>
                        </div>
                        
                        <div class="social-icon-wraper">
                            <a href="https://www.npmjs.com/~shanover" target="_blank">
                                <Popper content="NPM Profile" :hover="true" offsetDistance="0">
                                    <inline-svg :src="require('../assets/icons/npm.svg')" class="icon" />
                                </Popper>
                            </a>
                        </div>

                       

                        <div class="social-icon-wraper">
                            <a href="mailto:admin@shanover.dev" target="_blank">
                                <Popper content="Email" :hover="true" offsetDistance="0">
                                    <inline-svg :src="require('../assets/icons/email.svg')" class="icon" />
                                </Popper>
                            </a>
                        </div>
                       

                    </div>
                </div>

                <div class="career-objective">
                    <p style="color:#878787">Bio</p>
                    <p style="text-align:justify;text-justify: inter-word;">
                        {{ bio }}
                    </p>

                    <div class="skills mt-4">
                        <h3><strong>Python, SQL, Machine Learning, JavaScript, VueJS</strong></h3>
                    </div>
                    
                    <p class="mt-3">Based in Toronto, CA 🍁</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import InlineSvg from 'vue-inline-svg';
import Popper from "vue3-popper";

export default {
    name: 'IntroHeader',
    components: {
        InlineSvg,
        Popper
    },
    data() {
        return {
            mobileView: false,
            bio:"A professional with over four years of experience as full stack developer working with various firms. Have strong competency in Machine Learning and Artificial Intelligence."
        }
    },
    methods: {
        checkScreen() {
            let width = window.innerWidth;
            if (width <= 750) {
                this.mobileView = true;
            } else {
                this.mobileView = false;
            }
            return;
        }
    }
}
</script>

<style scoped> 
p code {
    color: #AAD7D9;
  }

  p span {
    color: #0B60B0;
  }

  @media (max-width: 600px) {
    p.import {
        display: none;
      overflow-x: auto;
      white-space: pre-wrap;
    }
  }

.wrapper {
     padding: 7%;
     text-align: left;
     width: 100%;
     overflow: hidden;
     box-sizing: border-box;
     background: #FEFBF6;
     color: #343443;
 }

 .collections {
     display: flex;
     flex-direction: row start;
     justify-content: space-around;
 }

 p{
    font-size: 1.3rem;
 }

 .name-wrapper>p {
     color: #363062;
     font-weight: 300;
     font-size: 1.5rem;
 }

 .symbi {
     font-size: 2rem;
     color: #CFFFDC;
     font-weight: normal;
     font-family: sans-serif;
 }

 .text-theme {
     color: black;
 }

 .name {
     font-size: 4rem;
     font-weight: 400;
 }

 .socials-wrapper {
     /* background: rgba(255, 255, 255, 0.55); */
     background:#F5F7F8;
     box-shadow: 0 2px 8px 0 rgba(31, 38, 135, 0.37);
     backdrop-filter: blur(4px);
     -webkit-backdrop-filter: blur(4px);
     border-radius: 2px;
     border: 1px solid rgba(255, 255, 255, 0.18);
     padding: 16px;
 }


 .career-objective {
     margin-top: 28px;
     text-align: left!important;;
 }

 .career-objective>p {
     margin-bottom: 6px;
 }


 .icon {
     cursor: pointer;
     width: 50px;
     height: 50px;
     margin: 10px;
     filter: grayscale(1);
     transition: 1ms ease-in;
 }

 @media only screen and (max-width: 750px) {
     .icon {
         width: 35px;
         height: 35px;
         margin: 5px;
     }
 }

 .icon:hover {
     filter: none;
     transform: scale(1.1);
 }

 .social-icon-wraper>a {
     color: black;
 }

 .socials-wrapper {
        display: flex;
        justify-content: space-around; /* Adjust the alignment as needed */
    }

    .social-icon-wraper {
        flex: 1; /* Each social icon wrapper takes equal space */
        max-width: 100px; /* Adjust the maximum width as needed */
        text-align: center;
        margin: 10px; /* Adjust the margin as needed */
    }

    .icon {
        max-width: 100%; /* Make sure the icon does not exceed the container width */
        height: auto; /* Maintain the aspect ratio of the icon */
    }

    @media only screen and (max-width: 600px) {
        /* Adjust styles for smaller screens */
        .socials-wrapper {
            flex-direction: column; /* Stack the icons vertically on smaller screens */
        }
    }

 .flicker-fast {
     animation: flicker 4s linear infinite;
 }

 @keyframes flicker {

     0%,
     19.999%,
     22%,
     62.999%,
     64%,
     64.999%,
     70%,
     100% {
         text-shadow: none;
         opacity: .99;
     }

     20%,
     21.999%,
     63%,
     63.999%,
     65%,
     69.999% {
         opacity: 0.4;
     }
 }

 .circle {
     width: 200px;
     height: 200px;
     border-radius: 50%;
     overflow: hidden;
     position: relative;
 }

 .circle video,img {
     width: 100%;
     height: 100%;
     object-fit: cover;
 }

 .circle-wrap {
     display: flex;
     justify-content: center;
     align-items: center;
     margin: 0;
 }

 .sub-line {
    color:#92C7CF;
    font-weight: 100!important;
     text-align: center;
     margin-top: 10px;
     margin-bottom: 50px;

 }</style>