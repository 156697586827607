<template>
    <Popper content="Udemy Course" hover="true" offsetDistance="4">
        <inline-svg :src="require('../assets/icons/udemy_ico.svg')" class="icon" />
    </Popper>
</template>

<script>

import InlineSvg from 'vue-inline-svg';
import Popper from "vue3-popper";

export default {
    name:'UdemyStamp',
    components:{
        InlineSvg,
        Popper
    }
}

</script>

<style scoped>
    .icon{
        height:18px;
        width:18px;
        opacity: 0.8;
        margin-left:6px;
        margin-right:6px;
    }
</style>