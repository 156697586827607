<template>
    <div class="wrapper">
        <div class="row">
            <div class="col-lg-4 col-12">
                <div class="p-3">
                    <h1>Education</h1>
                    <!-- <h6 class="p-1 text-dim">K-12 <strong>→</strong> 4 Year Bachelor's Degree</h6> -->
                </div>
            </div>
            <div class="col-lg-8 col-12">
                <div class="blur-body">
                    <p>Jan 2023 - Sept-2024</p>
                    <h1>Post Graduate Certificate in Artificial Intelligence & Machine Learning</h1>
                    <!-- <h2>Government Engineering College, Rajkot (GTU - Gujarat Tech. University), GJ, India</h2> -->
                    <h2>Lambton College, Toronto, ON, Canada</h2>
                </div>

                <div class="blur-body">
                    <p>2015 - 2019</p>
                    <h1>Bachelor of Computer Engineering</h1>
                    <h2>Government Engineering College, (GTU - Gujarat Tech. University), GJ, India</h2>
                    <h2>Degree Completion with <strong>8.14/10 CGPA</strong> in 2019 awarded by Gujarat Technological University</h2>
                </div>
                 <!-- <div class="blur-body">
                    <p>2014 - 2015</p>
                    <h1>12th Grade/Class, Higher Secondary Certificate</h1>
                    <h2>Kendriya Vidyalaya (CBSE),  *****, India</h2>
                    <h2>Kendriya Vidyalaya (CBSE), Ankleshwar, GJ, India</h2>
                    <h2>CGPA: 89.6%</h2>
                </div>
                <div class="blur-body">
                    <p>2012 - 2013</p>
                    <h1>10th Grade/Class, Senior Secondary Certificate</h1>
                    <h2>Kendriya Vidyalaya (CBSE), *****, India</h2>
                    <h2>Kendriya Vidyalaya (CBSE), Ankleshwar, GJ, India</h2>
                    <h2>CGPA: 9.6 CGPA</h2>
                </div> -->
                <!-- <div class="p-3">
                    <em>♾️ Year 2011</em>
                    <p class="mb-0">Started coding in Q-basic and C++</p>
                </div> -->
                <!-- <p><small><i class="fas fa-info-circle"></i>*Insitute names, and locations are hiddden pertaining to the fact that this portfolio is open to web and might be misused.</small></p> -->

            </div> 
        </div>
    </div>
</template>

<script>

import InlineSvg from 'vue-inline-svg';
import Popper from "vue3-popper";

export default {
    name:'Education',
     components:{
        InlineSvg,
        Popper
    }
}
</script>

<style scoped>
    .wrapper{
        padding:5%;
        font-family: 'Inter',sans-serif;
    }
    .blur-body{
        margin-bottom:20px;
    }

    .text-dim{
        color:#565656;
    }

    .blur-body > p{
        color:#454545;
        margin-bottom:7px;
    }
    .blur-body > h1{
        font-size: 1.2rem;
        margin-bottom:4px;
        color:#344CB7;
    }
    .blur-body > h2{
        font-size: 1rem;
        margin:0px;
        margin-bottom:4px;
        color:#434343;
    }

    .blur-body > ul > li{
        color:#454545;
        font-size:0.9rem;
    }

    .tech-stack:hover{
        transform: scale(1.1);
    }

    .computed{
        font-size:10px;
        color:#565656;
    }
</style>