<template>
    <div class="wrapper text-center">
        <h1 class="neon">"#<span class="flicker-slow">Open</span><span class="flicker-v-slow">ToWork</span>"</h1>
        <p class="pt-2">Available from Jan 2023</p>
        <p class="pt-2">Location: Toronto, ON, Canada <img class="flag" src="@/assets/icons/canada.png" alt="canada-flag" style="max-height:30px;"></p>
        <p class="pt-2">Preferred: Remote, Hourly-based</p>           

        <div class="contact-me" v-if="false">
            <h3>Drop your email and receive my resume directly in your inbox or ATS</h3>
            <p>(Ofcourse, I'll get the notification of this action and we can talk!)</p>

            <div class="contact-form" v-if="success!=1">
                <form @submit.prevent="sendForm">
                    <div class="field">
                        <label for="">Your work email address</label>
                        <input required type="email" placeholder="email@company.com" name="email" v-model="email">
                        <p v-if="workEmailErro" class="error">Sorry, only accepting 'Work Emails'</p>
                    </div>
                    <div class="field">
                        <button v-if="success==0" type="submit" class="btn-sb">Send Me</button>
                         
                        <button v-else-if="success==2" class="btn-sb" disabled>Sending...</button>
                        
                    </div>
                </form>
            </div>
            
            <div class="form-success" v-else>
                <h6>Thank you for your interest!</h6>
            </div>
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { db } from '../db.js'

export default {
    name:'Footer',
    components:{
        InlineSvg
    },
    data(){
        return {
            email:'',
            success:0,
            workEmailErro:false,
            blacklist:['gmail.com','yahoo.com','rediff.com','outlook.com']
        }
    },
    methods:{
        sendForm(){
            this.workEmailErro = false;
            if(this.checkIfCompanyMail()){
                this.workEmailErro = false;
                this.success=2;
                console.log('Sending form : ' + this.email);
                db.collection("users").add({
                    email:this.email
                })
                .then((docRef) => {
                    this.success = 1;
                    console.log("Document written with ID: ", docRef.id);
                })
                .catch((error) => {
                    console.error("Error adding document: ", error);
                });
            }else{
                this.workEmailErro = true;
            }
        },
        checkIfCompanyMail(){
            let allOk = true
            if(this.email && this.email.length>0 ){
                this.blacklist.forEach((val,ind,par)=>{
                    if(this.email.includes('@'+val)){
                        allOk = false
                    }
                })
            }
            return allOk;
        }
    }
}
</script>

<style scoped>
    .wrapper{
        padding:8%;
        font-family: 'Rajdhani',sans-serif;
        color:#fff;
        background-color: #e5e5f7;
        opacity: 0.8;
        background-image: linear-gradient(45deg, #0085b1 50%, #e5e5f7 50%);
        background-size: 5px 5px;
    }

    .error{
        margin:12px;
        font-size:1rem;
        color:#890F0D
    }

    .contact-me{
        padding:16px;
        margin:16px;
        background:#fafafa;
        border-radius: 3px;
        color:#222;
        font-family: 'Inter',serif;
    }

    .form-success{
        padding:22px;
        margin:16px;
        background:#B8FFF9;
    }

    .form-success > *{
        margin:0px;
        padding:0px;
        color:#767676;
    }

    .contact-form{
        padding:12px;
        margin:12px;
    }

    .field > label{
        display:block;
        font-size:0.9rem;
        color:#4D77FF;
    }

    .field{
        margin:12px;
    }

    .btn-sb{
        border:none;
        padding:10px 18px;
        font-size:1.1rem;
        background:#56BBF1;
        border-radius: 3px;
        color:#fff;
        margin:12px;
        box-shadow: 0px 0px 5px #f1f1f1;
        font-family: 'Inter',serif;
    }

    .btn-sb:hover{
        box-shadow: 0px 0px 8px #a8a9a9;
    }

    input{
        padding:8px 12px;
        border-radius: 6px;
        border:none;
        margin-top:8px;
        box-shadow: 0px 0px 3px #afafaf;
    }

    .contact-me > h3{
        color:#877887;
        font-size:1rem;
        margin:18px;
    }

    .contact-me > p{
        font-size:0.9rem;
        color:#878778;
        margin-bottom:0px;
    }

    .flag{
        height: 40px;
        max-height: 80px;
        display: inline-block;
        margin-right:12px;
    }

    .wrapper > p{
        font-size:1.2rem;
        color:#111;
        display: inline-block;
        background:#fff;
        padding-left:20px;
        padding-top:4px;
        padding-bottom:4px;
        padding-right:20px;
        border-radius: 4px;
        font-weight: bold;
    }

  

    .neon{
        font-weight: bolder;
        color: #fff;
        font-size: 4rem;
        letter-spacing: 8px;
    }

        .flicker-slow{
        animation: flicker 4s linear infinite;
        }

        .flicker-v-slow{
        animation: flicker 10s linear infinite;
        }

        .flicker-fast{
        animation: flicker 1s linear infinite;
        }

        @keyframes flicker {
            0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
                text-shadow: none;
                opacity: .99;                
            }
            20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
                opacity: 0.4;
            }
        }


</style>